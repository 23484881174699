import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  Tooltip,
} from 'react-leaflet'
import axios from 'axios'
import { useDispatch, connect } from 'react-redux'
import useSound from 'use-sound'
import L from 'leaflet'
import moment from 'moment'
import jwt_decode from 'jwt-decode'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import {
  Spin,
  Modal,
  Button,
  Space,
  Input,
  Drawer,
  Collapse,
  List,
  Typography,
  Divider,
  Select,
  Table,
  notification,
  Popconfirm,
  Form,
  Switch,
  Tooltip as TooltipAntd,
} from 'antd'
import {
  DeleteOutlined,
  SaveOutlined,
  FullscreenOutlined,
  InfoCircleOutlined,
  EnvironmentOutlined,
  SendOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import DefaultMap from './DefaultMap'
import Layout from './layout/Layout'
import FullScreenButton from './FullScreenButton'
import '../index.less'

// Redux actions
import {
  getLoc,
  getFilteredLoc,
  getFilteredLocMore,
  logout,
  sendSavedDevice,
  getSavedDevice,
  deleteSavedDevice,
  updateVideoDeleteStatus,
  updateVideoStatus,
} from '../redux/actions/user'

const columns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
  },
  {
    title: () => (
      <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
        <span style={{ fontSize: 12 }}>Accurancy </span>
        <TooltipAntd
          title="Lower value is more accurate"
          color={'#1890ff'}
          placement="bottom"
        >
          <InfoCircleOutlined style={{ color: '#1890ff' }} />
        </TooltipAntd>
      </div>
    ),
    dataIndex: 'accuracy',
    render: (accuracy) => <span>{accuracy}</span>,
    defaultSortOrder: '',
    sorter: (a, b) => a.accuracy - b.accuracy,
  },
  {
    title: 'Latitude ',
    dataIndex: 'lat',
  },
  {
    title: 'Longitude',
    dataIndex: 'lon',
  },
  {
    title: 'Speed',
    dataIndex: 'speed',
    render: (speed) => <span>{speed * 1.852} KM/h</span>,
    defaultSortOrder: '',
  },
  {
    title: 'Battery',
    dataIndex: 'batt',
    render: (batt) => <span>{batt}%</span>,
  },
  {
    title: 'Actions',
    dataIndex: 'googleMap',
    render: (googleMap) => (
      <a target="_blank" href={googleMap} rel="noreferrer">
        Google map
      </a>
    ),
  },
]
const data = []
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    date: `4th September 2021 7:12 AM ${i}`,
    address: `London, Park Lane no. ${i}`,
  })
}
const { Search } = Input
const { Panel } = Collapse
const { Title } = Typography
const { Option } = Select

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

const openNotification = (type, message) => {
  const args = {
    message,
    description: '',
    duration: 2,
    placement: 'bottomRight',
    type,
  }
  notification.open(args)
}

function SingleUserLocation(props) {
  const { user } = props
  const { location, filteredLocation, getLocationsLoading } = user // Redux actions

  const dispatch = useDispatch()
  const history = useHistory()
  const [play] = useSound(
    'https://assets.mixkit.co/sfx/download/mixkit-software-interface-start-2574.wav'
  )
  const limeOptions = { color: 'purple' }
  const positionOld = [23.8057082, 90.2539919]
  const polyline = [
    [23.8057082, 90.2539919],
    [23.805747, 90.253298],
    [23.8057082, 90.2539919],
  ]

  const [filter, setFilter] = useState('') // Filter to reuse on load more button
  const [loadMoreBtn, setLoadMoreBtn] = useState(false) // For getting more data if exists
  const [deviceIdentifier, setDeviceIdentifier] = useState('')
  // process.env.REACT_APP_ENV === 'development' ? '132021' : ''
  const [savedDevices, setSavedDevices] = useState([])
  const [fullScreenMap, setFullScreenMap] = useState(false)
  const [map, setmap] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [liveUpdate, setLiveUpdate] = useState(false)
  const [showgm, setShowgm] = useState(true) // Show google iframe
  const [updateCount, SetUpdateCount] = useState(0)
  const [userLoc, setUserLoc] = useState({}) // current user location based on browser (not accurate all the time)
  const [showDirection, setShowDirection] = useState(false) // show the direction iframe
  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark') // default to dark theme or theme from localStorage
  const [open, setOpen] = useState(false)
  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  const switchTheme = () => {
    if (theme === 'light') {
      setTheme('dark') // switch to dark theme
      require('../indexDarkl.less')
    } else {
      setTheme('light') // switch to light theme
      require('../index.less')
    }
    window.location.reload() // force a reload
  }

  useEffect(() => {
    console.log('ln202')
    console.log(theme)
    if (theme === 'light') {
      require('../index.less')
    } else {
      require('../indexDarkl.less')
    }
    localStorage.setItem('theme', theme)
    // window.location.reload() // force a reload
  }, [theme]) // rerun effect when `theme` changes

  useEffect(() => {
    function distance(lat1, lon1, lat2, lon2, unit) {
      var radlat1 = (Math.PI * lat1) / 180
      var radlat2 = (Math.PI * lat2) / 180
      var radlon1 = (Math.PI * lon1) / 180
      var radlon2 = (Math.PI * lon2) / 180
      var theta = lon1 - lon2
      var radtheta = (Math.PI * theta) / 180
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      if (unit == 'K') {
        dist = dist * 1.609344
      }
      if (unit == 'N') {
        dist = dist * 0.8684
      }
      return dist
    }

    // if (locations && locations.length > 2) {
    //   console.log(locations.length)
    //   console.log(locations[0].lat)
    //   console.log(locations[0].lon)
    //   var d = distance(
    //     locations[0].lat,
    //     locations[0].lon,
    //     locations[1].lat,
    //     locations[1].lon,
    //     'K'
    //   )
    //   //round to 3 decimal places
    //   // console.log(d)
    //   console.log(Math.round(d * 1000) / 1000) //displays 343.548
    // }
  }, []) // TODO: add locations array here on dependency

  useEffect(() => {
    // Check if user is logged in or not
    const token = localStorage.getItem('token')
    if (!token) {
      console.log('Log out')
      dispatch(logout())
      history.push('/authenticate')
    }
    // console.log(window.location.search.split('=')[1])

    dispatch(getSavedDevice({ setSavedDevices }))
    // This below portion only for super admin
    if (user?.user?.token) {
      const id = jwt_decode(user?.user?.token).id
      if (id === `61449185096220558855ba7f`) {
        setDeviceIdentifier('sm')
        handleGetLocations(`sm`) // One immediate upadate
        setShowgm(false) // for everyone else showing google map by default but for me showing openstreet map by default
      }
    }

    getMyLoc() // Get user location based on browser
  }, [])

  // Live updates
  useEffect(() => {
    let autoUpdate
    if (liveUpdate) {
      console.log('Start auto update')
      handleGetLocations(deviceIdentifier) // One immediate upadate
      autoUpdate = setInterval(() => {
        handleGetLocations(deviceIdentifier)
      }, 10000)
    }

    return () => {
      clearInterval(autoUpdate)
    }
  }, [liveUpdate])

  // Updating search count
  useEffect(() => {
    if (getLocationsLoading) SetUpdateCount(updateCount + 1)
  }, [getLocationsLoading])

  // Search btn & Saved device
  const handleGetLocations = (query) => {
    // event.preventDefault()
    // play()
    if (!query) {
      openNotification('error', 'Please type device identifier')
      return // empty input
    }
    openNotification('info', 'Getting locations...')
    dispatch(getLoc({ query, deviceIdentifier }))
  }

  const saveDevice = () => {
    dispatch(
      sendSavedDevice(deviceIdentifier, { setSavedDevices, savedDevices })
    )
  }

  // From async storage
  const deleteDevice = (name) => {
    dispatch(deleteSavedDevice(name, { setSavedDevices, savedDevices }))
  }

  const handleFilteredSearch = async (value) => {
    if (!value) return
    console.log(`${value}`)
    setFilter(value) // to use other places like on 'Load more'

    dispatch(getFilteredLoc({ value, deviceIdentifier, setLoadMoreBtn }))
  }

  const hanldeLoadMore = async () => {
    dispatch(getFilteredLocMore({ deviceIdentifier, filter, setLoadMoreBtn }))
  }

  // Dragable Marker settings
  // const [draggable, setDraggable] = useState(false)
  // const [position, setPosition] = useState(
  //   locations ? [locations[0].lat, locations[0].lon] : positionOld
  // )
  // const markerRef = useRef(null)
  // const eventHandlers = useMemo(
  //   () => ({
  //     dragend() {
  //       const marker = markerRef.current
  //       if (marker != null) {
  //         setPosition(marker.getLatLng())
  //       }
  //     },
  //   }),
  //   []
  // )
  // const toggleDraggable = useCallback(() => {
  //   setDraggable((d) => !d)
  // }, [])

  const onMapSet = (m) => {
    setmap(m)
    // ! This opening my position on search result, on expected result position.
    // m.locate({ setView: true, maxZoom: 18 })
    // m.on('locationfound', (data) => {
    //   console.log(data)
    //   L.circle([data.latlng.lat, data.latlng.lng], {
    //     radius: 20,
    //     color: 'red',
    //   }).addTo(m)
    // })
  }

  // Get location based on browser location
  const getMyLoc = () => {
    // If user allows the location permission at browser
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getPosition)
    }
    function getPosition(position) {
      console.log(position.coords.latitude, position.coords.longitude)
      // console.log(currentLoc)
      // alert('Hello')

      setUserLoc({
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      })
      if (map) {
        map.flyTo([position.coords.latitude * 1, position.coords.longitude * 1]) // Fly to found location, if previous location is far.
        // Creating locaiton on my location
        // Keep this circle on state, if multiple circles gets created in future.
        L.circleMarker(
          [position.coords.latitude * 1, position.coords.longitude * 1],
          {
            radius: 20,
            color: 'red',
          }
        ).addTo(map)
      }
    }
  }

  const onFinishPushNotification = async (e) => {
    const token = localStorage.getItem('token')
    const data = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/api/v1/client/sendNotification`,
      data: {
        exponentPushToken: location.exponentPushToken,
        title: e.title,
        body: e.body,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    openNotification('info', 'Notification request sent!')
  }

  // ! #######################################################################
  return (
    <Layout>
      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          {location && location.exponentPushToken
            ? location.exponentPushToken
            : ''}
        </p>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinishPushNotification}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please input your notification title!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Body"
            name="body"
            rules={[
              {
                required: true,
                message: 'Please input your notification body!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          // border: '1px solid blue',
        }}
      >
        <div className="App" style={{ margin: 0 }}>
          {location ? (
            <>
              {!showgm ? (
                <MapContainer
                  // style={{ border: '1px solid red' }}
                  center={location}
                  zoom={localStorage.getItem('openStreetMapZoom') || 17}
                  // scrollWheelZoom={false}
                  style={fullScreenMap ? { height: '100vh' } : {}}
                  whenCreated={onMapSet}
                >
                  <TileLayer
                    attribution='<a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />

                  {/* Last saved location */}
                  <Marker position={[location.lat, location.lon]}>
                    <Popup>
                      Last location at Time:{' '}
                      <strong>
                        {moment
                          .unix(`${location.timestamp}`)
                          .format('Do MMMM YYYY h:mm:ss A')}
                      </strong>{' '}
                      <br />
                      <span> Battery: {location.batt}%</span>
                      <br />
                      <span>
                        Accurancy: {Math.round(location.accuracy * 1000) / 1000}{' '}
                      </span>
                      <TooltipAntd
                        title="Lower value is more accurate"
                        color={'#1890ff'}
                        placement="bottom"
                      >
                        <InfoCircleOutlined style={{ color: '#1890ff' }} />
                      </TooltipAntd>
                      <br />
                      <span>
                        Speed was: {parseFloat(location.speed) * 1.852} KM/h
                      </span>
                      <br />
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/place/${location.lat},${location.lon}`}
                        style={{ marginLeft: '10%' }}
                        rel="noreferrer"
                      >
                        Open at google map
                      </a>
                    </Popup>
                    <Tooltip
                      direction="bottom"
                      offset={[10, 40]}
                      opacity={1}
                      permanent
                    >
                      {' '}
                      <span
                        style={{
                          color:
                            location.accuracy < 30
                              ? 'green'
                              : location.accuracy >= 31 &&
                                location.accuracy <= 60
                              ? 'blue'
                              : 'red',
                        }}
                      >
                        {Math.round(location.accuracy * 1000) / 1000}{' '}
                      </span>
                      <strong>
                        {moment.unix(location.timestamp).fromNow()}
                      </strong>
                    </Tooltip>
                  </Marker>

                  {filteredLocation.length > 0 && (
                    <>
                      {filteredLocation.map((el, idx) => {
                        return (
                          <Marker position={[el.lat, el.lon]}>
                            <Popup>
                              Time:{' '}
                              <strong>
                                {moment
                                  .unix(`${el.timestamp}`)
                                  .format('Do MMMM YYYY h:mm A')}
                              </strong>{' '}
                              <br />
                              <span> Battery: {el.batt}%</span>
                              <br />
                              <span>
                                Accurancy:{' '}
                                {Math.round(el.accuracy * 1000) / 1000}{' '}
                              </span>
                              <TooltipAntd
                                title="Lower value is more accurate"
                                color={'#1890ff'}
                                placement="bottom"
                              >
                                <InfoCircleOutlined
                                  style={{ color: '#1890ff' }}
                                />
                              </TooltipAntd>
                              <br />
                              <p>{location.speed}</p>
                              <span>
                                Speed was: {parseFloat(location.speed) * 1.852}{' '}
                                KM/h
                              </span>
                              <br />
                              <a
                                target="_blank"
                                href={`https://www.google.com/maps/place/${el.lat},${el.lon}`}
                                style={{ marginLeft: '10%' }}
                                rel="noreferrer"
                              >
                                Open at google map
                              </a>
                            </Popup>
                          </Marker>
                        )
                      })}
                    </>
                  )}
                  {/* {locations.map((el, idx) => {
                return (
                  <Marker key={idx} position={[el.lat, el.lon]}>
                    <Popup>
                      Time:{' '}
                      <strong>
                        {moment
                          .unix(`${el.timestamp}`)
                          .format('Do MMMM YYYY h:mm A')}
                      </strong>
                      <br />
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/place/${el.lat},${el.lon}`}
                      >
                        Open on google map
                      </a>
                    </Popup>
                  </Marker>
                )
              })} */}
                  {/* <Polyline pathOptions={limeOptions} positions={userLocations} /> */}
                  <FullScreenButton
                    title={fullScreenMap ? 'Minimize screen' : 'Full Screen'}
                    markerPosition={location}
                    description="This is a custom description!"
                    setFullScreenMap={setFullScreenMap}
                    fullScreenMap={fullScreenMap}
                    handleGetLocations={handleGetLocations}
                    deviceIdentifier={deviceIdentifier}
                  />
                </MapContainer>
              ) : (
                <>
                  {!showDirection ? (
                    <iframe
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      loading="lazy"
                      allowfullscreen
                      referrerpolicy="no-referrer-when-downgrade"
                      src={`https://www.google.com/maps/embed/v1/place?&maptype=roadmap&key=${process.env.REACT_APP_API_KEY}
    &q=${location?.lat},${location?.lon}&zoom=17`}
                    ></iframe>
                  ) : (
                    <iframe
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      loading="lazy"
                      allowfullscreen
                      referrerpolicy="no-referrer-when-downgrade"
                      src={`https://www.google.com/maps/embed/v1/directions?&maptype=roadmap&key=${process.env.REACT_APP_API_KEY}
    &origin=${userLoc?.lat},${userLoc?.lon}&destination=${location?.lat},${location?.lon}`}
                    ></iframe>
                  )}

                  <p>
                    {moment
                      .unix(`${location?.timestamp}`)
                      .format('h:mm A (Do MMMM YYYY) ')}
                    <br />
                    <span style={{ color: '#0f0f0f' }}>
                      Accurancy: {Math.round(location?.accuracy * 1000) / 1000}{' '}
                    </span>
                    <br />
                    <span> Battery: {location?.batt}%</span>
                  </p>
                </>
              )}

              {location.exponentPushToken && (
                <Button
                  onClick={() => {
                    // getMyLoc()
                    showModal()
                  }}
                >
                  <SendOutlined />
                </Button>
              )}
            </>
          ) : (
            <DefaultMap setUserLoc={setUserLoc} />
          )}

          {/* Maps Static API */}
          {/* <img
          src={`https://maps.googleapis.com/maps/api/staticmap?center=Berkeley,CA&zoom=14&size=400x400&maptype=satellite&key=${process.env.REACT_APP_API_KEY}`}
        /> */}

          <Button onClick={showDrawer} style={{ marginTop: 10 }}>
            Settings
          </Button>
          <Drawer
            title="Settings"
            placement="right"
            onClose={onClose}
            visible={open}
            // visible={true}
          >
            <Button onClick={switchTheme}>
              Switch to {theme === 'light' ? 'Dark' : 'Light'} Mode
            </Button>
            <br />
            {location && (
              <div style={{ marginTop: 10 }}>
                <TooltipAntd
                  style={{ display: 'block', marginTop: 10 }}
                  title="Total search since this visit"
                  color={'#1890ff'}
                  placement="bottom"
                >
                  <span> {updateCount} </span>
                  <InfoCircleOutlined style={{ color: '#1890ff' }} />
                </TooltipAntd>
                <Switch
                  style={{ display: 'block', marginTop: 10 }}
                  title="Change map"
                  unCheckedChildren="Open Street Map"
                  checkedChildren="Google Map"
                  checked={showgm}
                  onChange={() => {
                    setShowgm(!showgm)
                  }}
                />

                <Switch
                  style={{ display: 'block', marginTop: 10 }}
                  unCheckedChildren="Manual Updates"
                  checkedChildren="Auto update"
                  checked={liveUpdate}
                  onChange={() => {
                    setLiveUpdate(!liveUpdate)
                  }}
                />
                <div>
                  {!showgm ? (
                    <Button
                      style={{ display: 'block', marginTop: 10 }}
                      onClick={() => {
                        getMyLoc()
                        setOpen(false)
                      }}
                    >
                      Fly to your location
                      <EnvironmentOutlined />
                    </Button>
                  ) : (
                    <Button
                      style={{ display: 'block', marginTop: 10 }}
                      onClick={() => {
                        setShowDirection(!showDirection)
                        setOpen(false)
                      }}
                    >
                      {showDirection ? 'Hide' : 'Show'} Direction from your
                      location
                    </Button>
                  )}
                </div>
              </div>
            )}

            <Input
              style={{ marginTop: 10 }}
              placeholder={`${localStorage.getItem(
                'openStreetMapZoom'
              )}-Open street map zoom (Refresh!)`}
              onChange={(v) =>
                localStorage.setItem('openStreetMapZoom', v.target.value)
              }
            />
          </Drawer>

          <div
            style={{
              marginTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              // border: '1px solid white',
            }}
          >
            <div style={{ display: 'flex', width: '100%', maxWidth: 400 }}>
              <Search
                placeholder="Device Identifier"
                enterButton="Search"
                size="large"
                type="text"
                value={deviceIdentifier}
                onChange={(event) => {
                  setDeviceIdentifier(event.target.value)
                }}
                onSearch={handleGetLocations}
                loading={getLocationsLoading}
              />
              {deviceIdentifier && !savedDevices.includes(deviceIdentifier) && (
                <TooltipAntd title="Save the device">
                  <Button
                    loading={user.saveDeviceLoading}
                    onClick={() => {
                      saveDevice()
                    }}
                    style={{ height: 'auto' }}
                  >
                    <SaveOutlined />
                  </Button>
                </TooltipAntd>
              )}
            </div>
          </div>

          {location?.reverseResponse?.display_name && (
            <p>{location?.reverseResponse?.display_name}</p>
          )}

          {location && (
            <>
              <Button
                danger
                onClick={() => {
                  handleViewFileView(deviceIdentifier)
                }}
              >
                Download
              </Button>
            </>
          )}

          {location && (
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                type="dashed"
                size="small"
                onClick={() => {
                  dispatch(getLoc({ deviceIdentifier, type: 'previous' }))
                }}
              >
                Previous
              </Button>
              <Button
                type="dashed"
                size="small"
                onClick={() => {
                  dispatch(getLoc({ deviceIdentifier, type: 'next' }))
                }}
              >
                Next
              </Button>
            </div>
          )}
          {deviceIdentifier && (
            <>
              <Title level={4}>Add filter</Title>
              <Select
                placeholder="Select a range"
                // defaultValue="today" // don't use
                // style={{ width: 120 }}
                loading={getLocationsLoading}
                disabled={getLocationsLoading}
                onChange={handleFilteredSearch}
              >
                <Option value="today">Today</Option>
                <Option value="all">All</Option>
              </Select>
              {/* Filtered data */}
              {filteredLocation.length > 0 && (
                <Table
                  loading={getLocationsLoading}
                  columns={columns}
                  // dataSource={data}
                  dataSource={filteredLocation}
                  scroll={{ x: true }}
                  title={() =>
                    `Data for: ${filteredLocation[0].deviceIdentifier}`
                  }
                  onChange={onTableChange}
                />
              )}
              {loadMoreBtn && (
                <Button
                  onClick={() => {
                    hanldeLoadMore()
                  }}
                  loading={getLocationsLoading}
                >
                  Load more locations
                </Button>
              )}
            </>
          )}
          <div style={{ marginTop: 10 }}>
            <Collapse accordion defaultActiveKey={['1']} onChange={callback}>
              <Panel header="Saved devices" key="1">
                <List
                  header={
                    <div>
                      Click on the device name to get locations (Tip: Modify
                      from database to change order)
                    </div>
                  }
                  bordered
                  dataSource={savedDevices}
                  loading={user.getDevicesLoading || user.deleteDeviceLoading}
                  renderItem={(item) => (
                    <List.Item>
                      <Button
                        // type="link"
                        // style={{ color: '#e31286' }}
                        type="primary"
                        // ghost
                        onClick={() => {
                          setDeviceIdentifier(item) // To show value on search box, nothing important to below query of handleGetLocations()
                          handleGetLocations(item)
                          window.scroll({
                            top: 0,
                            behavior: 'smooth',
                          })
                        }}
                      >
                        {item}
                      </Button>
                      <Popconfirm
                        title="Are you sure to delete this?"
                        onConfirm={() => {
                          deleteDevice(item)
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button danger type="text">
                          <DeleteOutlined />
                        </Button>{' '}
                      </Popconfirm>
                    </List.Item>
                  )}
                />
              </Panel>
              {/* <Panel header="Videos" key="3">
              {user?.userDetails?.videos && (
                <List
                  header={<div>Click on the Video to play</div>}
                  bordered
                  dataSource={user.userDetails?.videos.filter(
                    (video) => !video.deleted
                  )}
                  loading={user.getDevicesLoading || user.deleteDeviceLoading}
                  renderItem={(item) => (
                    <List.Item
                      style={{
                        border: '1px solid white',
                        margin: 3,
                        padding: 4,
                        marginTop: 20,
                      }}
                    >
                      <span
                        onClick={() => {
                          window.open(item.url, '_blank').focus()
                        }}
                        style={{
                          color: '#fff',
                          cursor: 'pointer',
                          marginBottom: 20,
                        }}
                      >
                        {item.title}
                      </span>
                      <span style={{ color: !item.seen ? 'red' : '#50C878' }}>
                        {!item.seen ? 'দেখা বাকি' : 'দেখা শেষ'}
                      </span>
                      {item.url.includes('drive.google.com') ? (
                        <iframe
                          width="100%"
                          height="240"
                          allowFullScreen
                          src={item.url}
                        ></iframe>
                      ) : (
                        <video
                          width="100%"
                          height="240"
                          controls
                          src={item.url}
                        >
                          <source src="movie.mp4" type={item.url} />
                        </video>
                      )}
                      <p>
                        <Button
                          style={{ color: 'pink' }}
                          onClick={() => {
                            dispatch(updateVideoStatus(item.id, !item.seen))
                          }}
                        >
                          {!item.seen ? 'শেষ করুন' : 'পরে দেখব'}
                        </Button>
                      </p>
                      <br />
                      <Popconfirm
                        title="Are you sure to delete this?"
                        onConfirm={() => {
                          dispatch(updateVideoDeleteStatus(item.id))
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button danger type="text">
                          <DeleteOutlined />
                        </Button>{' '}
                      </Popconfirm>
                    </List.Item>
                  )}
                />
              )}
            </Panel>
            <Panel header="Delete History" key="4">
              <p>Coming soon ...</p>
            </Panel> */}
            </Collapse>
            {/* {JSON.stringify(user?.user)} */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

function callback(key) {
  // console.log(key)
}
function onTableChange(pagination, filters, sorter, extra) {
  // console.log('params', pagination, filters, sorter, extra)
}
const handleViewFileView = async (pushToken) => {
  try {
    // setLoading(true)

    const token = localStorage.getItem('token')
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER}/locationDownload/${pushToken}?limit=10000`,
      headers: {
        Authorization: `Bearer ${token}`,
        responseType: 'blob',
      },
    })

    // const response = await axios.get(
    //   `${process.env.REACT_APP_SERVER}/locationDownload/${pushToken}`,
    //   {
    //     responseType: 'blob',
    //   }
    // )

    // Create a blob object from the response data
    const blob = new Blob([response.data])

    // Create a URL for the blob
    const blobUrl = window.URL.createObjectURL(blob)

    // Create a link element and trigger the download
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = `${pushToken}.txt`
    link.click()

    // Clean up the URL object to release resources
    window.URL.revokeObjectURL(blobUrl)
    // setLoading(false)
  } catch (error) {
    console.error('Error downloading file:', error)
    // setLoading(false)
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps, {})(SingleUserLocation)
